<template>
  <ErrorBoundary
    :is-error="hasError"
    :component="() => import('pages/LinkNotAvailablePage')"
  >
    <div
      v-if="!loading"
      class="form-builder"
    >
      <AppStepper
        v-model="currentStepIndex"
        active-color="primary"
        done-color="primary"
        inactive-icon=""
        transition-next="fade"
        transition-prev="fade"
        animated
        alternative-labels
        header-nav
      >
        <q-step
          v-for="(step, i) in steps"
          :key="i"
          v-ripple="false"
          :name="i"
          :icon="`fa fa-${i + 1}`"
          :active-icon="`fa fa-${i + 1}`"
          :done-icon="`fa fa-${i + 1}`"
          :title="$t(step.title)"
          :done="currentStepIndex > i"
        >
          <div class="row q-gutter-x-xl">
            <div :class="['setting-column', { 'thank-you-page': $route.name === ENUMS.ROUTES.FORM.THANK_YOU_PAGE }]">
              <q-form
                ref="form"
                greedy
                @validation-error="onError"
              >
                <router-view/>
              </q-form>
              <q-stepper-navigation :class="{ 'q-mb-md': !isLastPage }">
                <AppButton
                  v-if="canBack"
                  align="between"
                  color="white"
                  text-color="black"
                  icon-left="fa fa-arrow-left-long"
                  class="form-builder__back-button"
                  outline
                  :label="$t('button.back')"
                  @click="checkRoute(currentStepIndex - 1)"
                />
                <AppButton
                  align="between"
                  color="primary"
                  text-color="black"
                  :icon-right="isLastPage ? 'fa-regular fa-circle-check' : 'fa fa-arrow-right-long'"
                  :label="btnLabel"
                  @click="checkRoute(currentStepIndex + 1)"
                />
              </q-stepper-navigation>
              <div
                v-if="!isLastPage"
                class="save-block"
              >
                <AppButton
                  align="between"
                  class="full-width"
                  color="white"
                  text-color="black"
                  icon-right="fa-regular fa-circle-check"
                  :label="$t('button.saveAndExit')"
                  :to="{ name: ENUMS.ROUTES.DASHBOARD.INDEX }"
                  outline
                />
              </div>
            </div>
            <div class="col column justify-between items-end">
              <keep-alive>
                <FormPreview class="form-sticky"/>
              </keep-alive>
              <AppButtonDropdown
                v-if="canCopyFormEmbedCode"
                :label="$t('button.copyCode')"
                :menu-offset="[0, 15]"
                color="black"
                class="copy-btn"
                icon="fl:duplicate"
                text-color="white"
                dropdown-icon="fa fa-angle-up"
                split
                @click="copyCode(code)"
              >
                <CodePage show-close-btn/>
              </AppButtonDropdown>
            </div>
          </div>
        </q-step>
      </AppStepper>
    </div>
  </ErrorBoundary>
</template>

<script>
import AppButtonDropdown from 'common/components//buttons/AppButtonDropdown';
import ErrorBoundary from 'common/components/ErrorBoundary';
import AppButton from 'common/components/buttons/AppButton';
import FormPreview from 'common/components/form/FormPreview';
import AppStepper from 'common/components/stepper/AppStepper';
import { INVALID_FORM_ACCESS, REST_NO_ROUTE } from 'common/enums/errorCodes';
import handleError from 'common/helpers/handleError';
import { notifyError } from 'common/helpers/notify';
import { copyCodeMixin } from 'common/mixins';
import CodePage from 'pages/dashboard/form/CodePage';
import formCode from 'src/helpers/formCode';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'FormEditLayout',
  components: {
    ErrorBoundary,
    CodePage,
    AppButtonDropdown,
    FormPreview,
    AppButton,
    AppStepper,
  },
  mixins: [copyCodeMixin],
  props: {
    guid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentStepIndex: undefined,
      loading: false,
    };
  },
  computed: {
    hasError() {
      return (!this.loading && this.$store.state.form.form === undefined)
      || (!this.canCopyFormEmbedCode && this.$route.name === ENUMS.ROUTES.FORM.CODE_PAGE);
    },
    isLastPage() {
      return this.currentStepIndex === this.steps.length - 1;
    },
    btnLabel() {
      return this.isLastPage
        ? this.$t('button.copyCodeFinish')
        : this.$t('button.next');
    },
    canBack() {
      return this.currentStepIndex > 0;
    },
    code() {
      return formCode(this.guid);
    },
    steps() {
      return [
        {
          title: 'label.setting',
          route: ENUMS.ROUTES.FORM.SETTING_PAGE,
          isActive: true,
        },
        {
          title: 'label.formFields',
          route: ENUMS.ROUTES.FORM.FORM_FIELDS_PAGE,
          isActive: true,
        },
        {
          title: 'label.notifications',
          route: ENUMS.ROUTES.FORM.NOTIFICATIONS_PAGE,
          isActive: true,
        },
        {
          title: 'label.thankYou',
          route: ENUMS.ROUTES.FORM.THANK_YOU_PAGE,
          isActive: true,
        },
        {
          title: 'label.autoresponders',
          route: ENUMS.ROUTES.FORM.AUTORESPONDERS_PAGE,
          isActive: true,
        },
        {
          title: 'label.design',
          route: ENUMS.ROUTES.FORM.DESIGN_PAGE,
          isActive: true,
        },
        {
          title: 'label.validation',
          route: ENUMS.ROUTES.FORM.VALIDATION_PAGE,
          isActive: true,
        },
        {
          title: 'label.code',
          route: ENUMS.ROUTES.FORM.CODE_PAGE,
          isActive: this.canCopyFormEmbedCode,
        },
      ].filter(({ isActive }) => isActive);
    },
  },
  watch: {
    currentStepIndex(to, from) {
      if (from === undefined) {
        return;
      }
      this.goTo(to, from);
    },
  },
  async created() {
    await this.fetch();

    const index = this.steps.findIndex((step) => step.route === this.$route.name);
    this.currentStepIndex = index === -1 ? 0 : index;
  },
  methods: {
    ...mapActions('form', ['fetchForm']),
    ...mapMutations('form', ['clear']),
    async fetch() {
      try {
        this.$q.loading.show();
        this.loading = true;
        await this.fetchForm(this.guid);
      } catch (error) {
        if ([INVALID_FORM_ACCESS, REST_NO_ROUTE].includes(error.response?.data?.code)) {
          notifyError(this.$t('error.formIsNotExist'));
        } else {
          handleError(error);
        }
      } finally {
        this.$q.loading.hide();
        this.loading = false;
      }
    },
    getRoute(step) {
      if (this.steps[step]) {
        return this.steps[step].route;
      }
      return undefined;
    },

    async goTo(to, from) {
      const toRoute = this.getRoute(to);
      const fromRoute = this.getRoute(from);
      const isValidateForm = await this.isValidateForm();

      if (this.$route.name === toRoute) {
        await this.isValidateForm();
        return;
      }

      if (from < to && !isValidateForm) {
        this.currentStepIndex = from;
        return;
      }

      this.$root.$emit(ENUMS.EVENTS.ON_CHANGE_STEP, {
        from: fromRoute,
        to: toRoute,
      });
      await this.$router.push({
        name: toRoute,
        params: { guid: this.guid },
      });
    },
    async checkRoute(newIndex) {
      if (newIndex > this.steps.length - 1) {
        this.$router.push({ name: ENUMS.ROUTES.DASHBOARD.INDEX })
          .then(() => {
            this.clear();
            this.copyCode(this.code);
          });
        return;
      }

      if (newIndex > this.currentStepIndex) {
        const isValidateForm = await this.isValidateForm();
        if (!isValidateForm) {
          return;
        }
      }

      this.currentStepIndex = newIndex;
    },
    async isValidateForm() {
      const validations = await Promise.all(this.$refs.form.map((item) => item.validate()));
      return validations.every((status) => status);
    },
    onError(component) {
      const openExpansionBlock = (parent) => {
        if (!parent) {
          return;
        }
        if (parent.$options.name === 'AppExpansionItem') {
          parent.isOpen = true;
          this.$nextTick(() => component.focus());
          this.$nextTick(() => document.activeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          }));
        } else {
          openExpansionBlock(parent.$parent);
        }
      };
      openExpansionBlock(component.$parent);
    },
  },
};
</script>
<style lang="scss" scoped>
.setting-column {
  @media (min-width: 0) {
    width: $form-width;
  }

  @media (min-width: $breakpoint-md) {
    width: 780px;
    max-width: unset;
  }

  &.thank-you-page {
    width: $form-width !important;
  }
}

.form-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 111px;
}

::v-deep.copy-btn {
  .q-btn-dropdown__arrow-container {
    .q-icon {
      font-size: 19px;
      padding: 16px;
    }
  }
}
</style>
